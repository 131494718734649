<template>
  <div class="p-0 bg-white" >
    <div class="bg-white">
      <div class="card my-2">
        <div class="p-2 text-right">
          <div>
            <button class="list_top_card_btn bg-gray-200 cta-text text-text1 py-2 px-4 mr-2 rounded-3xl" @click="$router.go(-1)">Discard</button>
            <button class="list_top_card_btn bg-primary cta-text text-white py-2 px-4 mr-2 rounded-3xl" @click="saveNewPage()">{{this.$route.params.blogId > 0 ? 'Update' : 'Save'}}</button>
          </div>  
        </div>
      </div>
      <div class="grid grid-cols-12 card p-2" >
        <div class="box lg:col-span-6 sm:col-span-12 md:col-span-12 xl:col-span-6 col-span-12 bg-white h-full card mx-1" :style="`height:` + cardHeight2 + `px;max-height:` + cardHeight2 + `px;overflow: auto; !important`">
          <div>
            <div class="p-2 pb-0 grid grid-cols-12">
              <div class="box col-span-12 p-2 pb-0">
                <p class="label_css">Blog Page Name</p>
                <input v-model="pageObj.blogPageName"
                :class="pagnameErr ? 'border border-error' : ''"
                class="outline-none  text-gray-900 text-sm rounded  block w-full pl-2 p-2.5 h-12 heading-4 border border-gray-500 focus:border-gray-800" 
                placeholder="Blog Page Name"
                >
                <p v-if="pagnameErr" class="text-error heading-6">Blog Name is Required</p>
              </div>
              <div class="box col-span-12 p-2 pb-0">
                <p class="label_css">Blog Page Display Title</p>
                <input v-model="pageObj.blogPageDisplayTitle"
                :class="dtitlexErr ? 'border border-error' : ''"
                class="outline-none  text-gray-900 text-sm rounded  block w-full pl-2 p-2.5 h-12 heading-4 border border-gray-500 focus:border-gray-800" 
                placeholder="Blog Page Display Title"
                >
                <p v-if="dtitlexErr" class="text-error heading-6">Blog Display Title is Required</p>
              </div>
              <div class="box col-span-12 p-2 pb-0">
                <p class="label_css">Page URL</p>
                <input v-model="pageObj.blogPageUrlSlug"
                :class="pageUrlErr ? 'border border-error' : ''"
                class="outline-none  text-gray-900 text-sm rounded  block w-full pl-2 p-2.5 h-12 heading-4 border border-gray-500 focus:border-gray-800" 
                placeholder="Page URL"
                >
                <p v-if="pageUrlErr" class="text-error heading-6">Page URL is Required</p>
              </div>
              <div class="box col-span-12 p-2 pb-0">
                <p class="label_css">Blog Page Description</p>
                <textarea class="outline-none  text-gray-900 text-sm rounded  block w-full pl-2 p-2.5 h-28 heading-4 border border-gray-500 focus:border-gray-800"
                  placeholder="Blog Page Short Description"
                  :class="descErr ? 'border border-error' : ''"
                  outlined=""
                  auto-grow=""
                  v-model="pageObj.blogPageShortDescription"
                ></textarea>
                <p v-if="descErr" class="text-error heading-6">Blog Page Description is Required</p>
              </div>
              <!-- <div class="box col-span-7 p-2 pb-0">
                <p class="label_css">Button URL</p>
                <input v-model="pageObj.blogPageDetailBtnUrl"
                :class="btnurlErr ? 'border border-error' : ''"
                class="outline-none  text-gray-900 text-sm rounded  block w-full pl-2 p-2.5 h-12 heading-4 border border-gray-500 focus:border-gray-800" 
                placeholder="Button URL"
                >
                <p v-if="btnurlErr" class="text-error heading-6">Button URL is Required</p>
              </div> -->
              <div class="box col-span-5 p-2 pb-0">
                <p class="label_css">Button Text</p>
                <input v-model="pageObj.blogPageDetailBtnText"
                :class="btntextErr ? 'border border-error' : ''"
                class="outline-none  text-gray-900 text-sm rounded  block w-full pl-2 p-2.5 h-12 heading-4 border border-gray-500 focus:border-gray-800" 
                placeholder="Button Text"
                >
                <p v-if="btntextErr" class="text-error heading-6">Button Text is Required</p>
              </div>
              <div class="box col-span-7 p-2 pb-0">
                <p class="label_css">Image/Video URL</p>
                <input v-model="pageObj.blogPageImageOrVideo"
                :class="imgurlErr ? 'border border-error' : ''"
                class="outline-none  text-gray-900 text-sm rounded  block w-full pl-2 p-2.5 h-12 heading-4 border border-gray-500 focus:border-gray-800" 
                placeholder="Image/Video URL"
                >
                <p v-if="imgurlErr" class="text-error heading-6">Image/Video URL is Required</p>
              </div>
              <div class="box col-span-5 p-2 pb-0">
                <p class="label_css">Image/Video Alt</p>
                <input v-model="pageObj.blogPageImageOrVideoAlt"
                :class="imgaltErr ? 'border border-error' : ''"
                class="outline-none  text-gray-900 text-sm rounded  block w-full pl-2 p-2.5 h-12 heading-4 border border-gray-500 focus:border-gray-800" 
                placeholder="Image/Video Alt"
                >
                <p v-if="imgaltErr" class="text-error heading-6">Image/Video Alt is Required</p>
              </div>
              <!-- <div class="box col-span-12 p-2 pt-4 flex items-center">
                <label class="cu_switch cursor-pointer" :class="!pageObj.isImageUrl ? 'ml-2' : ''">
                  <input type="checkbox"  v-model="pageObj.isImageUrl">
                  <span class="cu_slider cu_round"></span>
                </label>
                <p class="heading-5 text-text2 pl-3">{{pageObj.isImageUrl ? 'Image URL' : 'Video URL'}}</p>
              </div> -->
              <div class="box col-span-12 p-2 pt-4 flex items-center">
                <input type="checkbox" class="h-4 w-4 cursor-pointer" v-model="pageObj.isImageUrl">
                <p class=" text-text1 pl-2 heading-5">Image URL</p>
              </div>
              <div class="box col-span-12 p-2 pt-4 flex items-center">
                <label class="cu_switch cursor-pointer" :class="!pageObj.blogPageOpenInNewTab ? 'ml-2' : ''">
                  <input type="checkbox"  v-model="pageObj.blogPageOpenInNewTab">
                  <span class="cu_slider cu_round"></span>
                </label>
                <p class="heading-5 text-text2 pl-3">{{pageObj.blogPageOpenInNewTab ? 'Blog page button redirect new page.' : 'Blog page button redirect same page.'}}</p>
              </div>
            </div>
          </div>
          <div class="box col-span-12 p-2 pb-0 ml-2">
            <span  class="flex items-center">
            <span @click="expandJSDiv()" class="text-primary font-bold cursor-pointer">Page Tracking Code</span>
            <span v-if="javascriptCodeDiv === false" class="flex items-center"><i class="fas fa-angle-down text-primary h-5 w-5 ml-1"></i></span>
            <p v-else class="flex items-center"><i class="fas fa-angle-up text-primary h-5 w-5 ml-1"></i></p>
            </span>
          </div>
          <div v-if="javascriptCodeDiv">
          <div class="box col-span-12 p-2 pb-0">
            <p class="label_css">Script Tag(in Head)</p>
            <textarea
              class="outline-none text-gray-900 text-sm rounded block w-full pl-2 p-2.5 h-28 heading-4 border border-gray-500 focus:border-gray-800"
              placeholder="Script Tag(in Head)"
              outlined=""
              auto-grow=""
              v-model="pageObj.dynamicJavascriptCode.scriptTagInHead"
            ></textarea>
          </div>
          <div class="box col-span-12 p-2 pb-4">
            <p class="label_css">noScript Tag(in body)</p>
            <textarea
              class="outline-none text-gray-900 text-sm rounded block w-full pl-2 p-2.5 h-28 heading-4 border border-gray-500 focus:border-gray-800"
              placeholder="noScript Tag(in body)"
              outlined=""
              auto-grow=""
              v-model="pageObj.dynamicJavascriptCode.noScriptTagInBody"
            ></textarea>
          </div>
          </div>
        </div>
        <div ref="container" class="box lg:col-span-6 sm:col-span-12 md:col-span-12 xl:col-span-6 col-span-12 bg-white h-full card mx-1" :style="`height:` + cardHeight2 + `px;max-height:` + cardHeight2 + `px;overflow: auto; !important`">
          <draggable :list="allComponentsListArr" group="people" @start="drag = true" @end="dragArray" id='list'>
            <div v-for="(data, index) in allComponentsListArr" :key="index" class="min-w-min border border-gray2 rounded-xl shadow-md bg-white text-sm px-3 p-2 mt-4 m-2 items-center">
              <div class="">
                <div class="flex items-center justify-between">
                  <div class="right-16 cursor-pointer mr-1">
                    <i class="fa-solid fa-grip-vertical text-gray3 h-4 w-4"></i>
                  </div>
                  <button v-if="allComponentsListArr.length > 1" class="mr-1" @click="removeCardDetails(index)">
                    <i class="fas fa-trash-alt text-error heading-3"></i>
                  </button>
                </div>
                <div class="">
                  <div class="p-2 pb-0">
                    <p class="label_css">Heading</p>
                    <input @input="selectComponentErr = false" :ref="'messageInput' + index" :id="'messageInputBox'+index" v-model="data.heading" class="outline-none  text-gray-900 text-sm rounded  block w-full pl-2 p-2.5 h-12 heading-4 border border-gray-500 focus:border-gray-800"  placeholder="Heading">
                  </div>
                </div>
                <div class="py-2" v-if="tempCardTwoDescriptionIndex !== index">
                  <label class="label_css font-bold cursor-pointer" style="color: #1976D3" @click="openCardTwoListDescription(index)">{{data.blogPageLongDescription !== '' ? "+ Edit Long Description" : "+ Add Long Description"}}</label>
                </div>
                <div class="p-2" v-if="tempCardTwoDescriptionIndex === index">
                  <p class="label_css">Long Description</p>
                  <!-- <textarea @input="selectComponentErr = false" class="outline-none  text-gray-900 text-sm rounded  block w-full pl-2 p-2.5 h-28 heading-4 border border-gray-500 focus:border-gray-800"
                    placeholder="Blog Page Short Description"
                    outlined=""
                    auto-grow=""
                    v-model="data.blogPageLongDescription"
                  ></textarea> -->
                  <Editor v-model="data.blogPageLongDescription" class="heading-4 text-text2 h-auto" style="margin-top:4px;"></Editor>
                </div>
                <div class="grid grid-cols-12 gap-4 p-2 ">
                  <div class="mb-4 sm:mb-0 col-span-6">
                    <p class="label_css">Image/Video URL</p>
                    <input @input="selectComponentErr = false" v-model="data.blogPageImageOrVideo" class="outline-none  text-gray-900 text-sm rounded  block w-full pl-2 p-2.5 h-12 heading-4 border border-gray-500 focus:border-gray-800"  placeholder="Image/Video URL">
                  </div>
                  <div class="mb-4 sm:mb-0 col-span-6">
                    <p class="label_css">Image/Video Alt</p>
                    <input v-model="data.blogPageImageOrVideoAlt" class="outline-none  text-gray-900 text-sm rounded  block w-full pl-2 p-2.5 h-12 heading-4 border border-gray-500 focus:border-gray-800"  placeholder="Image/Video Alt">
                  </div>
                </div>
                <div class="flex items-center pl-2 pt-1">
                  <input type="checkbox" class="h-4 w-4 cursor-pointer" v-model="data.isImageUrl">
                  <p class=" text-text1 pl-2 heading-5">Image URL</p>
                </div>
                <!-- <div class="flex justify-end">
                  <button class=" rounded-full h-8 w-8 bg-error  mr-1" @click="removeCardDetails(index)">
                    <i class="fas fa-minus text-white heading-3"></i>
                  </button>
                  <button v-if="index === allComponentsListArr.length - 1" class=" rounded-full h-8 w-8 bg-primary" @click="addSection()">
                    <i class="fas fa-plus text-white heading-3"></i>
                  </button>
                </div> -->
                <div class="flex justify-end">
                  <button v-if="index === allComponentsListArr.length - 1" class="text-primary font-semibold heading-5 cursor-pointer pt-2" @click="addSection()">
                    + Add Component
                  </button>
                </div>
                <p v-if="selectComponentErr" class="text-error heading-6 pl-2 -mt-4">Please fill required values</p>
              </div>
            </div>
          </draggable>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Editor from '@/View/components/editor.vue'
import draggable from 'vuedraggable'
import HRM from '@/View/CMS/api/HRM.js'
export default {
  components: {
    draggable,
    Editor
  },
  data () {
    return {
      tempCardTwoDescriptionIndex: '',
      isAddingNewTextDrip: false,
      cardHeight2: 0,
      javascriptCodeDiv: false,
      selectComponentErr: false,
      drag: false,
      pageDetailObj: [],
      pagnameErr: false,
      dtitlexErr: false,
      pageUrlErr: false,
      descErr: false,
      btnurlErr: false,
      btntextErr: false,
      imgurlErr: false,
      imgaltErr: false,
      isError: true,
      componentList: [],
      allComponentsListArr: [],
      pageObj: {
        projectDetailId: 0,
        blogPageMasterId: 0,
        blogPageOpenInNewTab: false,
        isActive: true,
        blogPageDetailBtnText: '',
        blogPageUrlSlug: '',
        blogPageName: '',
        blogPageDisplayTitle: '',
        pageMetaRobots: '',
        blogPageDetailBtnUrl: '',
        blogPageImageOrVideo: '',
        blogPageImageOrVideoAlt: '',
        blogPageShortDescription: '',
        blogPageContentDetail: [],
        isImageUrl: false,
        dynamicJavascriptCode: {
          scriptTagInHead: "",
          noScriptTagInBody: ""
        }
      }
    }
  },
  created() {
    window.addEventListener("resize", this.resizeWindowHandler);
  },
  mounted () {
    this.resizeWindowHandler()
    this.staticComponentListDisplay = this.staticComponentList
    document.title = this.$route.params.blogId > 0 ? 'Edit Blog' : 'add New Blog'
    if (JSON.parse(this.$route.params.blogId) > 0) {
      this.getPageStructDetail()
    } else {
      this.addSection()
    }
  },
  watch: {
    pageObj: {
      handler () {
        console.log('pageObj 123', this.pageObj)
        if (this.pageObj.blogPageName !== '') {
          this.pagnameErr = false
          this.isError = false
        }
        if (this.pageObj.blogPageDisplayTitle !== '') {
          this.dtitlexErr = false
          this.isError = false
        }
        if (this.pageObj.blogPageUrlSlug !== '') {
          this.pageUrlErr = false
          this.isError = false
        }
        if (this.pageObj.blogPageShortDescription !== '') {
          this.descErr = false
          this.isError = false
        }
        // if (this.pageObj.blogPageDetailBtnUrl !== '') {
        //   this.btnurlErr = false
        //   this.isError = false
        // }
        if (this.pageObj.blogPageDetailBtnText !== '') {
          this.btntextErr = false
          this.isError = false
        }
        if (this.pageObj.blogPageImageOrVideo !== '') {
          this.imgurlErr = false
          this.isError = false
        }
        if (this.pageObj.blogPageImageOrVideoAlt !== '') {
          this.imgaltErr = false
          this.isError = false
        }
      },
      deep: true
    },
  },
  methods: {
    expandJSDiv () {
      this.javascriptCodeDiv = !this.javascriptCodeDiv;
    },
    getPageStructDetail () {
      this.$store.dispatch('SetLoader', {status: true, message: 'Loading...'})
      HRM.BlogPageDetail(
        JSON.parse(this.$route.params.blogId),
        response => {
          this.pageDetailObj = response.Data === null ? [] : response.Data
          console.log("response pageDetailObj", this.pageDetailObj)
          console.log("response pageObj", this.pageObj)
          this.pageObj.blogPageName = this.pageDetailObj.blogPageName
          this.pageObj.blogPageDetailBtnText = this.pageDetailObj.blogPageDetailBtnText
          this.pageObj.blogPageDetailBtnUrl = this.pageDetailObj.blogPageDetailBtnUrl
          this.pageObj.blogPageDisplayTitle = this.pageDetailObj.blogPageDisplayTitle
          this.pageObj.blogPageImageOrVideo = this.pageDetailObj.blogPageImageOrVideo
          this.pageObj.blogPageImageOrVideoAlt = this.pageDetailObj.blogPageImageOrVideoAlt
          this.pageObj.blogPageMasterId = this.pageDetailObj.blogPageMasterId
          this.pageObj.blogPageOpenInNewTab = this.pageDetailObj.blogPageOpenInNewTab
          this.pageObj.isImageUrl = this.pageDetailObj.isImageUrl
          this.pageObj.blogPageShortDescription = this.pageDetailObj.blogPageShortDescription
          this.pageObj.blogPageUrlSlug = this.pageDetailObj.blogPageUrlSlug
          this.pageObj.isActive = this.pageDetailObj.isActive
          this.pageObj.projectDetailId = this.pageDetailObj.projectDetailId
          this.allComponentsListArr = this.pageDetailObj.blogPageContentDetail
          if (this.pageDetailObj.dynamicJavascriptCode.scriptTagInHead !== '' || this.pageDetailObj.dynamicJavascriptCode.noScriptTagInBody !== '') {
            this.javascriptCodeDiv = true
          } else {
            this.javascriptCodeDiv = false
          }
          this.pageObj.dynamicJavascriptCode.scriptTagInHead = this.pageDetailObj.dynamicJavascriptCode.scriptTagInHead;
          this.pageObj.dynamicJavascriptCode.noScriptTagInBody = this.pageDetailObj.dynamicJavascriptCode.noScriptTagInBody;
          this.$store.dispatch('SetLoader', {status: false, message: ''})
        },
        error => {
          this.$store.dispatch('SetLoader', {status: false, message: ''})
          this.$store.dispatch('SetAlert', {showAlert: true, message: error.message, color: 'error'})
        }
      )
    },
    dragArray () {
      this.drag = false
    },
    removeCardDetails (index) {
      if (this.allComponentsListArr.length !== 1) {
        this.allComponentsListArr.splice(index, 1)
      }
    },
    addSection() {
      this.isAddingNewTextDrip = true
      console.log('add section')
      this.allComponentsListArr.push(
        {
          blogPageDetailId: 0,
          heading: '',
          isImageUrl: true,
          blogPageLongDescription: '',
          displayOrder: 0,
          blogPageImageOrVideo: '',
          blogPageImageOrVideoAlt: '',
        }
      )
      this.$nextTick(() => {
        const container = this.$refs.container;
        container.scrollTop = container.scrollHeight;
      });
      // this.$nextTick(() => {
      //   const lastIndex = this.allComponentsListArr.length - 1;
      //   const lastTextarea = this.$refs['messageInput' + lastIndex][0];
      //   if (lastTextarea) {
      //     lastTextarea.focus();
      //   }
      // });
      setTimeout(() => {
        this.isAddingNewTextDrip = false;
      }, 1000);
    },
    openCardTwoListDescription (index) {
      this.tempCardTwoDescriptionIndex = index
    },
    saveNewPage() {
      console.log('this.pageObj',  this.pageObj)
      if (this.allComponentsListArr.length === 0) {
        this.selectComponentErr = true;
        this.isError = true;
      } else {
        console.log('this.allComponentsListArr.length', this.allComponentsListArr.length)
        if (this.allComponentsListArr.length === 1) {
          let arr = this.allComponentsListArr[0]
          console.log('arr', arr)
          if (arr.heading === '' && arr.blogPageImageOrVideo === '' && arr.blogPageLongDescription === '') {
            console.log('arr', arr)
            this.selectComponentErr = true;
            this.isError = true
          } else {
            this.selectComponentErr = false;
            this.isError = false
          }
        } else {
          this.selectComponentErr = false;
          this.isError = false
        }
      }
      // } else {
      //   // Validate each component in allComponentsListArr
      //   this.selectComponentErr = this.allComponentsListArr.some(component => 
      //     component.heading === '' || 
      //     component.blogPageLongDescription === '' || 
      //     component.blogPageImageOrVideo === ''
      //   );
        
      //   // Update isError based on selectComponentErr
      //   this.isError = this.selectComponentErr;
      // }
      if (this.pageObj.blogPageName === '') {
        this.pagnameErr = true
        this.isError = true
      }
      if (this.pageObj.blogPageDisplayTitle === '') {
        this.dtitlexErr = true
        this.isError = true
      }
      if (this.pageObj.blogPageUrlSlug === '') {
        this.pageUrlErr = true
        this.isError = true
      }
      if (this.pageObj.blogPageShortDescription === '') {
        this.descErr = true
        this.isError = true
      }
      // if (this.pageObj.blogPageDetailBtnUrl === '') {
      //   this.btnurlErr = true
      //   this.isError = true
      // }
      if (this.pageObj.blogPageDetailBtnText === '') {
        this.btntextErr = true
        this.isError = true
      }
      if (this.pageObj.blogPageImageOrVideo === '') {
        this.imgurlErr = true
        this.isError = true
      }
      if (this.pageObj.blogPageImageOrVideoAlt === '') {
        this.imgaltErr = true
        this.isError = true
      }
      this.pageObj.blogPageMasterId = JSON.parse(this.$route.params.blogId)
      this.pageObj.projectDetailId = JSON.parse(this.$route.params.projectId)
      if (this.isError === false) {
        for (let index = 0; index < this.allComponentsListArr.length; index++) {
          let arr = this.allComponentsListArr[index]
          if (arr.heading === '' && arr.blogPageImageOrVideoAlt === '' && arr.blogPageImageOrVideo === '' && arr.blogPageLongDescription === '') {
            this.allComponentsListArr.splice(index, 1)
          }
        }
        this.pageObj.blogPageContentDetail = this.allComponentsListArr
        this.$store.dispatch('SetLoader', {status: true, message: 'Loading...'})
        HRM.BlogPageAddUpdate(
          this.pageObj,
          response => {
            this.$router.go(-1);
            this.$store.dispatch('SetLoader', {status: false, message: ''})
            this.$store.dispatch('SetAlert', {showAlert: true, message: response.message, color: 'success'})
          },
          error => {
            this.$store.dispatch('SetLoader', {status: false, message: ''})
            this.$store.dispatch('SetAlert', {showAlert: true, message: error.message, color: 'error'})
          }
        )
      }
    },
    resizeWindowHandler() {
      this.cardHeight2 = window.innerHeight - 180
    },
  },
  beforeDestroy () {
    this.$root.$off('closeComponentDialog')
  }
}
</script>
<style scoped>
.list_top_card_btn {
  width: 163px !important;
  margin-right: 15px !important;
}

::placeholder {
  font-size: 16px;
}
</style>